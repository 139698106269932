.ant-table-tbody > tr > td {
  border: none !important;
  /* padding: 1rem 1rem !important; */
  /* height: 50px; */
}

/* .ant-table-measure-row > td {
  display: none;
} */

.ant-table-thead > tr > th {
  cursor: pointer;
  border: none !important;
  background-color: white !important;
  padding: 1rem 1rem !important;
  /* width: 100px !important; */
  /* display: inline-block !important; */
}
.ant-table-cell {
  /* padding: 12px 1rem !important; */
  padding: 12px 1rem !important;
}

.ant-table-row:hover {
  cursor: pointer;
  background-color: none !important;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #edf8ff;
}

.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  width: 0 !important;
}

/* .ant-table-cell {
    background-color: #2a3042 !important;
  } */

.ant-table-content {
  /* overflow-x: hidden !important; */
  overflow-x: scroll !important;
}
.ant-table-content:hover {
  overflow-x: scroll !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ant-table,
.ant-table-tbody,
.ant-table-content {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  box-shadow: none !important;
}

.ant-table-body {
  overflow: auto !important;
  /* background-color: red !important; */
  box-shadow: none !important;
}

.ant-table:hover,
.ant-table-tbody:hover,
.ant-table-content:hover {
  -ms-overflow-style: inherit !important;
  scrollbar-width: inherit !important;
}

.icon-button {
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 7px;
  margin: 0px 3px;
  transition: all 0.4s ease;
}

.icon-button:hover {
  background-color: rgb(21, 7, 38, 0.04);
}
.search-dropdown-icon {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.ant-switch-checked {
  background-color: #6d69f8 !important;
}
.nobg-btn {
  transition: all 0.3s ease-in-out;
}
.nobg-btn:hover {
  background: #e1e0e8 !important;
}
.filter-applied {
  background-color: rgba(72, 94, 196, 0.2);
  color: rgba(72, 94, 196, 0.8);
}
.filter-applied:hover {
  color: rgba(72, 94, 196, 1) !important;
  background-color: rgba(72, 94, 196, 0.2) !important;
}
.clear-filter-btn {
  text-decoration: underline;
  color: rgba(72, 94, 196, 1) !important;
  cursor: pointer;
}
