.custom-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  /* width: 100px; */
  /* width: 60px;
  height: 60px; */
}
.custom-field-icon {
  background-color: white;
  color: black;
  /* width: 50px;
  height: 50px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.custom-field-icon i {
  padding: 1rem;
  font-size: 1.6rem;
}
.custom-field:hover .custom-field-icon {
  background-color: #556ee6;
  color: white;
}

.ant-collapse-content-box {
  padding-top: 0px !important;
}
