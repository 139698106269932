.user-settings-tabs .ant-tabs-tab-active div {
  color: #666 !important;
}
.user-settings-tabs .ant-tabs-tab-active {
  border: none !important;
}

.user-settings-tabs .ant-tabs-tab:hover {
  color: #666 !important;
}
.user-settings-tabs .ant-tabs-ink-bar-animated {
  background-color: #666 !important;
}
