.custom-card {
  text-align: center;
  display: block;
  height: 110px;
  width: 100px;
  color: #666;
  margin: 0rem 1rem;
  margin-bottom: 4rem;
}
.custom-card-box {
  background-color: white;
  border-radius: 4px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-card-box i {
  font-size: 2rem;
}
.custom-card p {
  margin: 0.8rem 0px;
  color: #333;
}
.custom-card-box:hover {
  background-color: #6d69f8;
  color: white;
}

.search-input {
  border-radius: 8px;
  background-color: none;
}

.ant-dropdown-menu {
  padding: 4px 0px !important;
  width: 200px !important;
  box-shadow: 0 1px 5px rgba(129, 129, 129, 0.1), 0 1px 5px rgba(0, 0, 0, 0.1) !important;
}
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  box-shadow: none !important;
}
.ant-dropdown-menu-submenu {
  background-color: white !important;
  /* display: block !important; */

  padding: 0 !important;
  margin: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}

.radio-hover {
  padding: 0.2rem 1rem;
  padding-top: 0.6rem;
  transition: all 0.3s ease;
}
.radio-hover:hover {
  background-color: #eff2f7 !important;
}

.select2-selection__control {
  background-color: yellow !important;
}

.workspace-select {
  color: white !important;
  width: 100%;
  text-align: left;
  border-radius: 6px;
  padding: 1px 0px;
  border: 1px solid #fafafa;
}

/* .metismenu {
  padding-left: 0px !important;
} */
